import request from '@/utils/request.js'

/**
 * 获取权限列表
 * @param {Object} data
 * @returns
 */
export function getPermissionList(data) {
  return request({
    url: 'api/permission/GetPermissionList',
    method: 'post',
    data,
  })
}

/**
 * 新增权限项
 * @param {Object} data
 * @returns
 */
export function addNewPermission(data) {
  return request({
    url: 'api/permission/AddNew',
    method: 'post',
    data,
  })
}

/**
 * 获取所有权限节点的树形结构
 * @returns
 */
export function getPermissionTress() {
  return request({
    url: 'api/permission/GetPermissionTree',
    method: 'get',
  })
}

/**
 * 获取所有权限项类型节点
 * @returns
 */
export function getPermissionTypeOptions() {
  return request({
    url: 'api/permission/GetPermissionTypeOptions',
    method: 'get',
  })
}

/**
 * 获取所有权限项目
 * @returns
 */
export function getPermissions() {
  return request({
    url: 'api/permission/GetPermissions',
    method: 'get',
  })
}

/**
 * 删除对应id的权限项目
 * @param {Number} id
 * @returns
 */
export function deletePermission(id) {
  return request({
    url: 'api/permission/deletePermission',
    method: 'get',
    params: {
      id,
    },
  })
}

/**
 * 更新当前权限项
 * @param {Object} data
 * @returns
 */
export function updatePermission(data) {
  return request({
    url: 'api/permission/updatePermission',
    method: 'post',
    data,
  })
}

/**
 * 根据权限id获取权限详情
 * @param {number} id
 * @returns
 */
export function getPermissionById(id) {
  return request({
    url: 'api/permission/GetInfoById',
    method: 'get',
    params: { id },
  })
}
