<template>
  <el-card>
    <div slot="header">
      <span>权限管理</span>
    </div>
    <!--
    <el-row :gutter="20">
      <el-col :span="6">
        <el-input
          type="text"
          placeholder="请输入权限名称或者权限代码进行筛选"
          v-model="queryFilter.keyWorlds"
        />
      </el-col>
      <el-button type="primary" @click="falshTable">搜索权限</el-button>
      <el-button
        style="float: right"
        type="success"
        @click="addPermissionDialogVisabled = true"
        >新增权限</el-button
      >
    </el-row>
    -->
    <!--
    <el-row style="margin-top: 20px">
      
      <el-table stripe border :data="tableData">
        <el-table-column label="#" width="80" type="index"></el-table-column>
        <el-table-column prop="name" label="权限名称"></el-table-column>
        <el-table-column
          prop="permissionCode"
          label="权限代码"
        ></el-table-column>
        <el-table-column
          prop="parentName"
          label="父级权限名称"
        ></el-table-column>
        <el-table-column prop="url" label="路径"></el-table-column>
        <el-table-column
          prop="permissionTypeDisplayName"
          label="权限类型"
        ></el-table-column>
        <el-table-column prop="description" label="权限描述"></el-table-column>
        <el-table-column label="编辑">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="openEditPermissionDialog(scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="deletePermission(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
     
    </el-row>

    <el-pagination
      style="margin-top: 20px; text-align: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryFilter.pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="queryFilter.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="queryFilter.total"
    >
    </el-pagination>
     -->
    <!-- <el-row>
      <el-button
        style="float: right"
        type="success"
        @click="addPermissionDialogVisabled = true"
        >新增权限</el-button
      >
    </el-row> -->
    <el-row style="margin-top: 20px">
      <el-input placeholder="输入权限关键字进行过滤" v-model="filterText">
      </el-input>
    </el-row>

    <el-row style="margin-top: 20px">
      <el-button
        size="mini"
        type="primary"
        @click="addPermissionDialogVisabled = true"
        >新增权限选项</el-button
      >
      <el-button size="mini" type="success" @click="openTree"
        >展开全部</el-button
      >
      <el-button size="mini" type="info" @click="closeTree">折叠全部</el-button>
      <el-button size="mini" type="info" @click="routerUrl = !routerUrl"
        >切换Url显隐</el-button
      >
    </el-row>
    <el-row style="margin-top: 20px">
      <el-alert
        show-icon
        title="提示:黑色为页面访问权限,蓝色为接口访问权限"
        type="warning"
        :closable="false"
      >
      </el-alert>
    </el-row>
    <el-row>
      <el-scrollbar style="height: 600px">
        <el-tree
          ref="tree"
          :check-strictly="false"
          :data="tableData"
          default-expand-all
          :props="defaultProps"
          :filter-node-method="filterNode"
          node-key="id"
        >
          <div style="width: 800px" slot-scope="{ node, data }">
            <span
              :style="{
                color: data.permissionType == 0 ? '#303133' : '#409EFF',
              }"
              >{{ node.label }}</span
            >
            <span
              v-show="routerUrl"
              :style="{
                color: data.permissionType == 0 ? '#303133' : '#409EFF',
                paddingLeft: '20px',
              }"
              >{{ data.url }}</span
            >
            <span style="float: right">
              <el-button
                type="text"
                size="mini"
                style="color: #67c23a"
                @click.stop="openAddNewPermissionDialogFromParent(data)"
              >
                新增子权限
              </el-button>
              <el-button
                type="text"
                size="mini"
                @click.stop="openEditPermissionDialog(data)"
              >
                编辑
              </el-button>
              <el-button
                type="text"
                size="mini"
                style="color: red"
                @click.stop="deletePermission(data)"
              >
                删除
              </el-button>
            </span>
          </div></el-tree
        >
      </el-scrollbar>
    </el-row>

    <!-- 新增权限项目 -->
    <el-dialog
      :close-on-click-modal="false"
      title="新增权限项"
      :visible.sync="addPermissionDialogVisabled"
      @close="addNewPermissionDialogClose"
    >
      <el-form
        ref="addPermissionFromRef"
        :model="addPermissionForm"
        :rules="addPermissionFormRules"
        label-width="100"
      >
        <el-form-item prop="name" label="权限名称">
          <el-input
            v-model="addPermissionForm.name"
            autocomplete="off"
            placeholder="权限名称"
          ></el-input>
        </el-form-item>

        <el-form-item prop="type" label="权限类型">
          <el-select
            v-model="addPermissionForm.type"
            filterable
            @focus="onFocus"
            reserve-keyword
            placeholder="请选择权限类型"
          >
            <el-option
              v-for="item in permissionTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="parentId" label="父级权限名称">
          <el-select
            v-model="addPermissionForm.parentId"
            filterable
            @focus="onFocus"
            reserve-keyword
            placeholder="请输入父级权限名称"
          >
            <el-option
              v-for="item in permissions"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="url" label="权限地址">
          <el-input
            v-model="addPermissionForm.url"
            autocomplete="off"
            placeholder="权限地址"
          ></el-input>
        </el-form-item>

        <el-form-item prop="description" label="权限描述">
          <el-input
            type="textarea"
            maxlength="300"
            :rows="2"
            show-word-limit
            v-model="addPermissionForm.description"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="addPermissionDialogVisabled = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="addNewPermissionDialogConfirm"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 编辑权限项目 -->
    <el-dialog
      :close-on-click-modal="false"
      title="新增权限项"
      :visible.sync="editPermissionDialogVisabled"
    >
      <el-form
        ref="editPermissionFromRef"
        :model="eidtPermissionForm"
        :rules="eidtPermissionFormRules"
        label-width="100"
      >
        <el-form-item prop="name" label="权限名称">
          <el-input
            v-model="eidtPermissionForm.name"
            autocomplete="off"
            placeholder="权限名称"
          ></el-input>
        </el-form-item>

        <el-form-item prop="type" label="权限类型">
          <el-select
            v-model="eidtPermissionForm.type"
            filterable
            @focus="onFocus"
            reserve-keyword
            placeholder="请选择权限类型"
          >
            <el-option
              v-for="item in permissionTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="parentId" label="父级权限名称">
          <el-select
            v-model="eidtPermissionForm.parentId"
            filterable
            @focus="onFocus"
            reserve-keyword
            placeholder="请输入父级权限名称"
          >
            <el-option
              v-for="item in permissions"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="url" label="权限地址">
          <el-input
            v-model="eidtPermissionForm.url"
            autocomplete="off"
            placeholder="权限地址"
          ></el-input>
        </el-form-item>

        <el-form-item prop="description" label="权限描述">
          <el-input
            type="textarea"
            maxlength="300"
            :rows="2"
            show-word-limit
            v-model="eidtPermissionForm.description"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="editPermissionDialogVisabled = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="editNewPermissionDialogConfirm"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import {
  getPermissionList,
  addNewPermission,
  getPermissionTypeOptions,
  getPermissions,
  updatePermission,
  getPermissionById,
  deletePermission,
  getPermissionTress,
} from '@/api/permission.js'
import { iosOnFocus } from '@/utils/iosPlatform.js'
export default {
  data() {
    return {
      // 筛选条件
      queryFilter: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
        keyWorlds: '',
        status: null,
      },
      defaultProps: {
        children: 'childrenPermission',
        label: 'name',
      },
      filterText: '',
      // 表格数据
      tableData: [],
      permissions: [],
      permissionTypeOptions: [],
      // 新增权限对话框
      addPermissionDialogVisabled: false,
      addPermissionForm: {
        name: '',
        parentId: null,
        url: '',
        type: null,
        description: '',
      },
      addPermissionFormRules: {
        name: [{ required: true, message: '权限名为必填项', trigger: 'blur' }],
        type: [
          { required: true, message: '权限类型为必填项', trigger: 'blur' },
        ],
        parentId: null,
        description: null,
      },
      editPermissionDialogVisabled: false,
      eidtPermissionForm: {
        id: null,
        name: '',
        parentId: null,
        url: '',
        type: null,
        description: '',
      },
      eidtPermissionFormRules: {
        name: [{ required: true, message: '权限名为必填项', trigger: 'blur' }],
        type: [
          { required: true, message: '权限类型为必填项', trigger: 'blur' },
        ],
        parentId: null,
        description: null,
      },
      routerUrl: false,
    }
  },
  created() {
    this.getTableData()
    this.getPermissions()
    this.getPermissionTypes()
  },
  methods: {
    // 分页大小更改
    handleSizeChange(value) {
      this.queryFilter.pageSize = value
      this.getTableData()
    },
    falshTable() {
      this.queryFilter.pageIndex = 1
      this.getTableData()
    },
    // 当前索引更改
    handleCurrentChange(val) {
      this.queryFilter.pageIndex = val
      this.getTableData()
    },
    async getTableData() {
      let { data } = await getPermissionTress(this.queryFilter)

      this.tableData = data

      // this.queryFilter.total = data.total
      // this.queryFilter.pageIndex = data.pageIndex
      // this.queryFilter.pageSize = data.pageSize
    },
    async getPermissionTypes() {
      let { data } = await getPermissionTypeOptions()
      this.permissionTypeOptions = data
    },
    async getPermissions() {
      let { data } = await getPermissions()

      this.permissions = data.map((item) => {
        return { id: item.id, value: item.name }
      })
    },
    // 新增权限项对话框确认
    addNewPermissionDialogConfirm() {
      this.$refs.addPermissionFromRef.validate(async (valid) => {
        if (valid) {
          await addNewPermission(this.addPermissionForm)

          this.getTableData()
          this.getPermissions()

          this.$message.success('新增权限选项成功')
          this.addPermissionDialogVisabled = false
        }
      })
    },
    // 新增权限项对话框关闭
    addNewPermissionDialogClose() {
      this.$refs.addPermissionFromRef.resetFields()
    },
    async openEditPermissionDialog(row) {
      let { data } = await getPermissionById(row.id)

      this.eidtPermissionForm.id = data.id
      this.eidtPermissionForm.name = data.name
      this.eidtPermissionForm.parentId = data.parentId
      this.eidtPermissionForm.url = data.url
      this.eidtPermissionForm.type = data.permissionType
      this.eidtPermissionForm.description = data.description

      this.editPermissionDialogVisabled = true
    },
    //
    editNewPermissionDialogConfirm() {
      this.$refs.editPermissionFromRef.validate(async (valid) => {
        if (valid) {
          await updatePermission(this.eidtPermissionForm)

          this.getTableData()
          this.getPermissions()

          this.$message.success('编辑权限选项成功')
          this.editPermissionDialogVisabled = false
        }
      })
    },
    deletePermission(row) {
      this.$confirm('此操作将永久删除该权限项目, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          await deletePermission(row.id)
          this.getTableData()
          this.getPermissions()
          this.$message.success('权限删除成功')
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    filterNode(value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
    openAddNewPermissionDialogFromParent(data) {
      this.addPermissionForm.parentId = data.id
      this.addPermissionDialogVisabled = true
    },

    closeTree() {
      for (var i = 0; i < this.$refs.tree.store._getAllNodes().length; i++) {
        this.$refs.tree.store._getAllNodes()[i].expanded = false
      }
    },

    openTree() {
      for (var i = 0; i < this.$refs.tree.store._getAllNodes().length; i++) {
        this.$refs.tree.store._getAllNodes()[i].expanded = true
      }
    },
    onFocus(event) {
      iosOnFocus.call(this,event);
    },
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    },
  },
}
</script>

<style lang="scss" scoped>
.el-select {
  width: 100%;
}
/* element滚动条组件 隐藏水平滚动条 */
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}
::v-deep .is-horizontal {
  display: none;
}
</style>
